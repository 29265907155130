import React, { useMemo, useState } from 'react';
import { ModalComponent as ModalWrapper } from './ModalWrapper';
import { useTranslationContext } from '../../context/TranslationContext';
var UnMemoizedGallery = function (props) {
    var images = props.images;
    var _a = useState(0), index = _a[0], setIndex = _a[1];
    var _b = useState(false), modalOpen = _b[0], setModalOpen = _b[1];
    var t = useTranslationContext('Gallery').t;
    var toggleModal = function (selectedIndex) {
        if (modalOpen) {
            setModalOpen(false);
        }
        else {
            setIndex(selectedIndex);
            setModalOpen(true);
        }
    };
    var formattedArray = useMemo(function () {
        return images.map(function (image) { return ({
            source: image.image_url || image.thumb_url || '',
        }); });
    }, [images]);
    var renderImages = images.slice(0, 3).map(function (image, i) { return (React.createElement("div", { className: 'str-chat__gallery-image', "data-testid": 'gallery-image', key: "gallery-image-" + i, onClick: function () { return toggleModal(i); } },
        React.createElement("img", { src: image.image_url || image.thumb_url }))); });
    return (React.createElement("div", { className: "str-chat__gallery " + (images.length > 3 ? 'str-chat__gallery--square' : '') },
        renderImages,
        images.length > 3 && (React.createElement("div", { className: 'str-chat__gallery-placeholder', onClick: function () { return toggleModal(3); }, style: {
                backgroundImage: "url(" + images[3].image_url + ")",
            } },
            React.createElement("p", null, t('{{ imageCount }} more', {
                imageCount: images.length - 3,
            })))),
        React.createElement(ModalWrapper, { images: formattedArray, index: index, modalIsOpen: modalOpen, toggleModal: function () { return setModalOpen(!modalOpen); } })));
};
/**
 * Displays images in a simple responsive grid with a light box to view the images.
 */
export var Gallery = React.memo(UnMemoizedGallery);
