import React from 'react';
import { PictureIcon } from './PictureIcon';
import { useHandleFileChangeWrapper } from '../utils';
export var ImageUploadButton = function (props) {
    var _a = props.multiple, multiple = _a === void 0 ? false : _a, _b = props.disabled, disabled = _b === void 0 ? false : _b, handleFiles = props.handleFiles, _c = props.children, children = _c === void 0 ? React.createElement(PictureIcon, null) : _c, _d = props.resetOnChange, resetOnChange = _d === void 0 ? false : _d;
    var onFileChange = useHandleFileChangeWrapper(resetOnChange, handleFiles);
    return (React.createElement("div", { className: "rfu-image-upload-button" },
        React.createElement("label", null,
            React.createElement("input", { type: "file", className: "rfu-image-input", onChange: onFileChange, accept: "image/*", multiple: multiple, disabled: disabled }),
            children)));
};
