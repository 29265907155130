import React, { useCallback } from 'react';
import { IconButton } from './IconButton';
import { FilePlaceholder } from './FilePlaceholder';
var svg = '<svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path d="M465 5c5.53 0 10 4.47 10 10s-4.47 10-10 10-10-4.47-10-10 4.47-10 10-10zm3.59 5L465 13.59 461.41 10 460 11.41l3.59 3.59-3.59 3.59 1.41 1.41 3.59-3.59 3.59 3.59 1.41-1.41-3.59-3.59 3.59-3.59-1.41-1.41z" id="b"/><filter x="-30%" y="-30%" width="160%" height="160%" filterUnits="objectBoundingBox" id="a"><feOffset in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1"/></filter></defs><g transform="translate(-451 -1)" fill-rule="nonzero" fill="none"><use fill="#000" filter="url(#a)" xlink:href="#b"/><use fill="#FFF" fill-rule="evenodd" xlink:href="#b"/></g></svg>';
export var Thumbnail = function (props) {
    var id = props.id, image = props.image, _a = props.size, size = _a === void 0 ? 100 : _a, handleClose = props.handleClose, alt = props.alt;
    var onClose = useCallback(function () { return handleClose === null || handleClose === void 0 ? void 0 : handleClose(id); }, [id, handleClose]);
    return (React.createElement("div", { className: "rfu-thumbnail__wrapper", style: { width: size, height: size } },
        React.createElement("div", { className: "rfu-thumbnail__overlay" }, handleClose ? (React.createElement(IconButton, { onClick: onClose },
            React.createElement("div", { dangerouslySetInnerHTML: { __html: svg } }))) : null),
        image ? (React.createElement("img", { src: image, className: "rfu-thumbnail__image", alt: alt !== null && alt !== void 0 ? alt : '' })) : (React.createElement(FilePlaceholder, { preserveAspectRatio: "xMinYMin slice", className: "rfu-thumbnail__image" }))));
};
