import React, { useCallback, useEffect, useRef, useState } from 'react';
var progressUpdateInterval = 500;
var UnMemoizedAudio = function (props) {
    var og = props.og;
    var _a = useState(false), isPlaying = _a[0], setIsPlaying = _a[1];
    var _b = useState(0), progress = _b[0], setProgress = _b[1];
    var audioRef = useRef(null);
    var updateProgress = useCallback(function () {
        if (audioRef.current !== null) {
            var position = audioRef.current.currentTime;
            var duration = audioRef.current.duration;
            var currentProgress = (100 / duration) * position;
            setProgress(currentProgress);
            if (position === duration) {
                setIsPlaying(false);
            }
        }
    }, [audioRef]);
    useEffect(function () {
        if (audioRef.current !== null) {
            if (isPlaying) {
                audioRef.current.play();
                var interval_1 = setInterval(updateProgress, progressUpdateInterval);
                return function () { return clearInterval(interval_1); };
            }
            audioRef.current.pause();
        }
        return;
    }, [isPlaying, updateProgress]);
    var asset_url = og.asset_url, description = og.description, image_url = og.image_url, text = og.text, title = og.title;
    return (React.createElement("div", { className: 'str-chat__audio' },
        React.createElement("div", { className: 'str-chat__audio__wrapper' },
            React.createElement("audio", { ref: audioRef },
                React.createElement("source", { "data-testid": 'audio-source', src: asset_url, type: 'audio/mp3' })),
            React.createElement("div", { className: 'str-chat__audio__image' },
                React.createElement("div", { className: 'str-chat__audio__image--overlay' }, !isPlaying ? (React.createElement("div", { className: 'str-chat__audio__image--button', "data-testid": 'play-audio', onClick: function () { return setIsPlaying(true); } },
                    React.createElement("svg", { height: '40', viewBox: '0 0 64 64', width: '40', xmlns: 'http://www.w3.org/2000/svg' },
                        React.createElement("path", { d: 'M32 58c14.36 0 26-11.64 26-26S46.36 6 32 6 6 17.64 6 32s11.64 26 26 26zm0 6C14.327 64 0 49.673 0 32 0 14.327 14.327 0 32 0c17.673 0 32 14.327 32 32 0 17.673-14.327 32-32 32zm13.237-28.412L26.135 45.625a3.27 3.27 0 0 1-4.426-1.4 3.319 3.319 0 0 1-.372-1.47L21 23.36c-.032-1.823 1.41-3.327 3.222-3.358a3.263 3.263 0 0 1 1.473.322l19.438 9.36a3.311 3.311 0 0 1 .103 5.905z', fillRule: 'nonzero' })))) : (React.createElement("div", { className: 'str-chat__audio__image--button', "data-testid": 'pause-audio', onClick: function () { return setIsPlaying(false); } },
                    React.createElement("svg", { height: '40', viewBox: '0 0 64 64', width: '40', xmlns: 'http://www.w3.org/2000/svg' },
                        React.createElement("path", { d: 'M32 58.215c14.478 0 26.215-11.737 26.215-26.215S46.478 5.785 32 5.785 5.785 17.522 5.785 32 17.522 58.215 32 58.215zM32 64C14.327 64 0 49.673 0 32 0 14.327 14.327 0 32 0c17.673 0 32 14.327 32 32 0 17.673-14.327 32-32 32zm-7.412-45.56h2.892a2.17 2.17 0 0 1 2.17 2.17v23.865a2.17 2.17 0 0 1-2.17 2.17h-2.892a2.17 2.17 0 0 1-2.17-2.17V20.61a2.17 2.17 0 0 1 2.17-2.17zm12.293 0h2.893a2.17 2.17 0 0 1 2.17 2.17v23.865a2.17 2.17 0 0 1-2.17 2.17h-2.893a2.17 2.17 0 0 1-2.17-2.17V20.61a2.17 2.17 0 0 1 2.17-2.17z', fillRule: 'nonzero' }))))),
                image_url && React.createElement("img", { alt: "" + description, src: image_url })),
            React.createElement("div", { className: 'str-chat__audio__content' },
                React.createElement("span", { className: 'str-chat__audio__content--title' },
                    React.createElement("strong", null, title)),
                React.createElement("span", { className: 'str-chat__audio__content--subtitle' }, text),
                React.createElement("div", { className: 'str-chat__audio__content--progress' },
                    React.createElement("div", { "data-testid": 'audio-progress', style: { width: progress + "%" } }))))));
};
/**
 * Audio attachment with play/pause button and progress bar
 */
export var Audio = React.memo(UnMemoizedAudio);
