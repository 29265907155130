import React from 'react';
import { Avatar } from '../Avatar';
import { useChannelActionContext } from '../../context/ChannelActionContext';
import { useComponentContext } from '../../context/ComponentContext';
import { useTranslationContext } from '../../context/TranslationContext';
var QuotedMessagePreviewHeader = function () {
    var setQuotedMessage = useChannelActionContext('QuotedMessagePreview').setQuotedMessage;
    var t = useTranslationContext('QuotedMessagePreview').t;
    return (React.createElement("div", { className: 'quoted-message-preview-header' },
        React.createElement("div", null, t('Reply to Message')),
        React.createElement("button", { className: 'str-chat__square-button', onClick: function () { return setQuotedMessage(undefined); } },
            React.createElement("svg", { height: '10', width: '10', xmlns: 'http://www.w3.org/2000/svg' },
                React.createElement("path", { d: 'M9.916 1.027L8.973.084 5 4.058 1.027.084l-.943.943L4.058 5 .084 8.973l.943.943L5 5.942l3.973 3.974.943-.943L5.942 5z', fillRule: 'evenodd' })))));
};
export var QuotedMessagePreview = function (props) {
    var _a;
    var quotedMessage = props.quotedMessage;
    var Attachment = useComponentContext('QuotedMessagePreview').Attachment;
    var userLanguage = useTranslationContext('QuotedMessagePreview').userLanguage;
    var quotedMessageText = ((_a = quotedMessage.i18n) === null || _a === void 0 ? void 0 : _a[userLanguage + "_text"]) ||
        quotedMessage.text;
    // @ts-expect-error
    var quotedMessageAttachment = quotedMessage.attachments.length
        ? // @ts-expect-error
            quotedMessage.attachments[0]
        : null;
    if (!quotedMessageText && !quotedMessageAttachment)
        return null;
    return (React.createElement("div", { className: 'quoted-message-preview' },
        React.createElement(QuotedMessagePreviewHeader, null),
        React.createElement("div", { className: 'quoted-message-preview-content' },
            quotedMessage.user && (React.createElement(Avatar, { image: quotedMessage.user.image, name: quotedMessage.user.name || quotedMessage.user.id, size: 20, user: quotedMessage.user })),
            React.createElement("div", { className: 'quoted-message-preview-content-inner' },
                quotedMessageAttachment && React.createElement(Attachment, { attachments: [quotedMessageAttachment] }),
                React.createElement("div", null, quotedMessageText)))));
};
