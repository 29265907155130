import React from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { ModalImage } from './ModalImage';
export var ModalComponent = function (props) {
    var images = props.images, index = props.index, modalIsOpen = props.modalIsOpen, toggleModal = props.toggleModal;
    return (React.createElement(ModalGateway, null, modalIsOpen ? (React.createElement(Modal, { onClose: toggleModal },
        React.createElement(Carousel, { components: {
                // @ts-expect-error
                View: ModalImage,
            }, currentIndex: index, views: images }))) : null));
};
