import React from 'react';
var UnMemoizedAttachmentActions = function (props) {
    var actionHandler = props.actionHandler, actions = props.actions, id = props.id, text = props.text;
    var handleActionClick = function (event, name, value) {
        if (actionHandler) {
            actionHandler(name, value, event);
        }
    };
    return (React.createElement("div", { className: 'str-chat__message-attachment-actions' },
        React.createElement("div", { className: 'str-chat__message-attachment-actions-form' },
            React.createElement("span", { key: 0 }, text),
            actions.map(function (action) { return (React.createElement("button", { className: "str-chat__message-attachment-actions-button str-chat__message-attachment-actions-button--" + action.style, "data-testid": "" + action.name, "data-value": action.value, key: id + "-" + action.value, onClick: function (event) { return handleActionClick(event, action.name, action.value); } }, action.text)); }))));
};
/**
 * A component for rendering the actions you can take on an attachment.
 */
export var AttachmentActions = React.memo(UnMemoizedAttachmentActions);
