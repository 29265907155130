import React from 'react';
import { FileIcon } from './FileIcon';
import { LoadingIndicator } from './LoadingIndicator';
/**
 * Component that displays files which are being uploaded
 */
export var FilePreviewer = function (_a) {
    var uploads = _a.uploads, handleRemove = _a.handleRemove, handleRetry = _a.handleRetry;
    return (React.createElement("div", { className: "rfu-file-previewer" },
        React.createElement("ol", null, uploads &&
            uploads.map(function (upload) { return (React.createElement("li", { key: upload.id, className: "rfu-file-previewer__file " + (upload.state === 'uploading'
                    ? 'rfu-file-previewer__file--uploading'
                    : '') + " " + (upload.state === 'failed'
                    ? 'rfu-file-previewer__file--failed'
                    : '') },
                React.createElement(FileIcon, { mimeType: upload.file.type, filename: upload.file.name }),
                React.createElement("a", { href: upload.url, download: true },
                    upload.file.name,
                    upload.state === 'failed' && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "rfu-file-previewer__failed", onClick: handleRetry && (function () { return handleRetry(upload.id); }) }, "failed"),
                        React.createElement("div", { className: "rfu-file-previewer__retry", onClick: handleRetry && (function () { return handleRetry(upload.id); }) }, "retry")))),
                React.createElement("span", { className: "rfu-file-previewer__close-button", onClick: handleRemove && (function () { return handleRemove(upload.id); }) }, "\u2718"),
                upload.state === 'uploading' && (React.createElement("div", { className: "rfu-file-previewer__loading-indicator" },
                    React.createElement(LoadingIndicator, null))))); }))));
};
