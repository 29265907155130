import React from 'react';
import { Avatar as DefaultAvatar } from '../Avatar';
/**
 * UI component for mentions rendered in suggestion list
 */
var UnMemoizedUserItem = function (props) {
    var _a = props.Avatar, Avatar = _a === void 0 ? DefaultAvatar : _a, entity = props.entity;
    var hasEntity = Object.keys(entity).length;
    var itemParts = entity === null || entity === void 0 ? void 0 : entity.itemNameParts;
    var renderName = function () {
        if (!hasEntity)
            return null;
        return (hasEntity &&
            itemParts.parts.map(function (part, i) {
                return part.toLowerCase() === itemParts.match.toLowerCase() ? (React.createElement("span", { className: 'str-chat__emoji-item--highlight', key: "part-" + i }, part)) : (React.createElement("span", { className: 'str-chat__emoji-item--part', key: "part-" + i }, part));
            }));
    };
    return (React.createElement("div", { className: 'str-chat__user-item' },
        React.createElement(Avatar, { image: entity.image, name: entity.name || entity.id, size: 20 }),
        React.createElement("span", { className: 'str-chat__user-item--name', "data-testid": 'user-item-name' }, renderName())));
};
export var UserItem = React.memo(UnMemoizedUserItem);
