var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { isUserMuted, validateAndGetMessage } from '../utils';
import { useChannelStateContext } from '../../../context/ChannelStateContext';
import { useChatContext } from '../../../context/ChatContext';
import { useTranslationContext } from '../../../context/TranslationContext';
export var missingUseMuteHandlerParamsWarning = 'useMuteHandler was called but it is missing one or more necessary parameter.';
export var useMuteHandler = function (message, notifications) {
    if (notifications === void 0) { notifications = {}; }
    var mutes = useChannelStateContext('useMuteHandler').mutes;
    var client = useChatContext('useMuteHandler').client;
    var t = useTranslationContext('useMuteHandler').t;
    return function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var getErrorNotification, getSuccessNotification, notify, successMessage, e_1, errorMessage, fallbackMessage, successMessage, e_2, errorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    event.preventDefault();
                    getErrorNotification = notifications.getErrorNotification, getSuccessNotification = notifications.getSuccessNotification, notify = notifications.notify;
                    if (!t || !(message === null || message === void 0 ? void 0 : message.user) || !notify || !client) {
                        console.warn(missingUseMuteHandlerParamsWarning);
                        return [2 /*return*/];
                    }
                    if (!!isUserMuted(message, mutes)) return [3 /*break*/, 5];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, client.muteUser(message.user.id)];
                case 2:
                    _a.sent();
                    successMessage = getSuccessNotification && validateAndGetMessage(getSuccessNotification, [message.user]);
                    notify(successMessage ||
                        t("{{ user }} has been muted", {
                            user: message.user.name || message.user.id,
                        }), 'success');
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    errorMessage = getErrorNotification && validateAndGetMessage(getErrorNotification, [message.user]);
                    notify(errorMessage || t('Error muting a user ...'), 'error');
                    return [3 /*break*/, 4];
                case 4: return [3 /*break*/, 8];
                case 5:
                    _a.trys.push([5, 7, , 8]);
                    return [4 /*yield*/, client.unmuteUser(message.user.id)];
                case 6:
                    _a.sent();
                    fallbackMessage = t("{{ user }} has been unmuted", {
                        user: message.user.name || message.user.id,
                    });
                    successMessage = (getSuccessNotification &&
                        validateAndGetMessage(getSuccessNotification, [message.user])) ||
                        fallbackMessage;
                    if (typeof successMessage === 'string') {
                        notify(successMessage, 'success');
                    }
                    return [3 /*break*/, 8];
                case 7:
                    e_2 = _a.sent();
                    errorMessage = (getErrorNotification && validateAndGetMessage(getErrorNotification, [message.user])) ||
                        t('Error unmuting a user ...');
                    if (typeof errorMessage === 'string') {
                        notify(errorMessage, 'error');
                    }
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    }); };
};
