import { useEffect, useState } from 'react';
import throttle from 'lodash.throttle';
var getDeviceWidth = function (width) {
    if (width < 768)
        return { device: 'mobile', width: width };
    if (width < 1024)
        return { device: 'tablet', width: width };
    return { device: 'full', width: width };
};
export var useBreakpoint = function () {
    var _a = useState(getDeviceWidth(window.innerWidth)), breakpoint = _a[0], setBreakpoint = _a[1];
    useEffect(function () {
        var getInnerWidth = throttle(function () { return setBreakpoint(getDeviceWidth(window.innerWidth)); }, 200);
        window.addEventListener('resize', getInnerWidth);
        return function () { return window.removeEventListener('resize', getInnerWidth); };
    }, []);
    return breakpoint;
};
