import React from 'react';
import { useTranslationContext } from '../../context/TranslationContext';
var UnMemoizedEmptyStateIndicator = function (props) {
    var listType = props.listType;
    var t = useTranslationContext('EmptyStateIndicator').t;
    if (listType === 'channel')
        return React.createElement("p", null, t('You have no channels currently'));
    if (listType === 'message')
        return null;
    return React.createElement("p", null, "No items exist");
};
export var EmptyStateIndicator = React.memo(UnMemoizedEmptyStateIndicator);
