import { useChannelStateContext } from '../../../context/ChannelStateContext';
import { useChatContext } from '../../../context/ChatContext';
export var useUserRole = function (message, onlySenderCanEdit, disableQuotedMessages) {
    var _a, _b, _c;
    var _d = useChannelStateContext('useUserRole'), channel = _d.channel, _e = _d.channelCapabilities, channelCapabilities = _e === void 0 ? {} : _e, channelConfig = _d.channelConfig;
    var client = useChatContext('useUserRole').client;
    var isAdmin = ((_a = client.user) === null || _a === void 0 ? void 0 : _a.role) === 'admin' || channel.state.membership.role === 'admin';
    var isMyMessage = client.userID === ((_b = message.user) === null || _b === void 0 ? void 0 : _b.id);
    var isOwner = channel.state.membership.role === 'owner';
    var isModerator = ((_c = client.user) === null || _c === void 0 ? void 0 : _c.role) === 'channel_moderator' ||
        channel.state.membership.role === 'channel_moderator' ||
        channel.state.membership.role === 'moderator';
    var canEdit = (!onlySenderCanEdit && channelCapabilities['update-any-message']) ||
        (isMyMessage && channelCapabilities['update-own-message']);
    var canDelete = channelCapabilities['delete-any-message'] ||
        (isMyMessage && channelCapabilities['delete-own-message']);
    var canFlag = !isMyMessage;
    var canMute = !isMyMessage && (channelConfig === null || channelConfig === void 0 ? void 0 : channelConfig.mutes);
    var canQuote = !disableQuotedMessages;
    var canReact = (channelConfig === null || channelConfig === void 0 ? void 0 : channelConfig.reactions) !== false && channelCapabilities['send-reaction'];
    var canReply = (channelConfig === null || channelConfig === void 0 ? void 0 : channelConfig.replies) !== false && channelCapabilities['send-reply'];
    return {
        canDelete: canDelete,
        canEdit: canEdit,
        canFlag: canFlag,
        canMute: canMute,
        canQuote: canQuote,
        canReact: canReact,
        canReply: canReply,
        isAdmin: isAdmin,
        isModerator: isModerator,
        isMyMessage: isMyMessage,
        isOwner: isOwner,
    };
};
