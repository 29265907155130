import React from 'react';
import { DeliveredCheckIcon } from './icons';
import { getReadByTooltipText } from './utils';
import { Avatar as DefaultAvatar } from '../Avatar';
import { LoadingIndicator } from '../Loading';
import { Tooltip } from '../Tooltip';
import { useChatContext } from '../../context/ChatContext';
import { useComponentContext } from '../../context/ComponentContext';
import { useMessageContext } from '../../context/MessageContext';
import { useTranslationContext } from '../../context/TranslationContext';
var UnMemoizedMessageStatus = function (props) {
    var _a;
    var propAvatar = props.Avatar, _b = props.messageType, messageType = _b === void 0 ? 'simple' : _b;
    var client = useChatContext('MessageStatus').client;
    var contextAvatar = useComponentContext('MessageStatus').Avatar;
    var _c = useMessageContext('MessageStatus'), isMyMessage = _c.isMyMessage, lastReceivedId = _c.lastReceivedId, message = _c.message, readBy = _c.readBy, threadList = _c.threadList;
    var t = useTranslationContext('MessageStatus').t;
    var Avatar = propAvatar || contextAvatar || DefaultAvatar;
    if (!isMyMessage() || message.type === 'error') {
        return null;
    }
    var justReadByMe = (readBy === null || readBy === void 0 ? void 0 : readBy.length) === 1 && readBy[0].id === ((_a = client.user) === null || _a === void 0 ? void 0 : _a.id);
    if (message.status === 'sending') {
        return (React.createElement("span", { className: "str-chat__message-" + messageType + "-status", "data-testid": 'message-status-sending' },
            React.createElement(Tooltip, null, t('Sending...')),
            React.createElement(LoadingIndicator, null)));
    }
    if ((readBy === null || readBy === void 0 ? void 0 : readBy.length) && !threadList && !justReadByMe) {
        var lastReadUser = readBy.filter(function (item) { var _a; return item.id !== ((_a = client.user) === null || _a === void 0 ? void 0 : _a.id); })[0];
        return (React.createElement("span", { className: "str-chat__message-" + messageType + "-status", "data-testid": 'message-status-read-by' },
            React.createElement(Tooltip, null, getReadByTooltipText(readBy, t, client)),
            React.createElement(Avatar, { image: lastReadUser.image, name: lastReadUser.name || lastReadUser.id, size: 15, user: lastReadUser }),
            readBy.length > 2 && (React.createElement("span", { className: "str-chat__message-" + messageType + "-status-number", "data-testid": 'message-status-read-by-many' }, readBy.length - 1))));
    }
    if (message.status === 'received' && message.id === lastReceivedId && !threadList) {
        return (React.createElement("span", { className: "str-chat__message-" + messageType + "-status", "data-testid": 'message-status-received' },
            React.createElement(Tooltip, null, t('Delivered')),
            React.createElement(DeliveredCheckIcon, null)));
    }
    return null;
};
export var MessageStatus = React.memo(UnMemoizedMessageStatus);
