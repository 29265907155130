import React from 'react';
import { ActionsIcon as DefaultActionsIcon, ReactionIcon as DefaultReactionIcon, ThreadIcon as DefaultThreadIcon, } from './icons';
import { MESSAGE_ACTIONS, showMessageActionsBox } from './utils';
import { MessageActions } from '../MessageActions';
import { useMessageContext } from '../../context/MessageContext';
var UnMemoizedMessageOptions = function (props) {
    var _a = props.ActionsIcon, ActionsIcon = _a === void 0 ? DefaultActionsIcon : _a, _b = props.displayLeft, displayLeft = _b === void 0 ? true : _b, _c = props.displayReplies, displayReplies = _c === void 0 ? true : _c, propHandleOpenThread = props.handleOpenThread, messageWrapperRef = props.messageWrapperRef, _d = props.ReactionIcon, ReactionIcon = _d === void 0 ? DefaultReactionIcon : _d, _e = props.theme, theme = _e === void 0 ? 'simple' : _e, _f = props.ThreadIcon, ThreadIcon = _f === void 0 ? DefaultThreadIcon : _f;
    var _g = useMessageContext('MessageOptions'), customMessageActions = _g.customMessageActions, getMessageActions = _g.getMessageActions, contextHandleOpenThread = _g.handleOpenThread, initialMessage = _g.initialMessage, isMyMessage = _g.isMyMessage, message = _g.message, onReactionListClick = _g.onReactionListClick, threadList = _g.threadList;
    var handleOpenThread = propHandleOpenThread || contextHandleOpenThread;
    var messageActions = getMessageActions();
    var showActionsBox = showMessageActionsBox(messageActions) || !!customMessageActions;
    var shouldShowReactions = messageActions.indexOf(MESSAGE_ACTIONS.react) > -1;
    var shouldShowReplies = messageActions.indexOf(MESSAGE_ACTIONS.reply) > -1 && displayReplies && !threadList;
    if (!message.type ||
        message.type === 'error' ||
        message.type === 'system' ||
        message.type === 'ephemeral' ||
        message.status === 'failed' ||
        message.status === 'sending' ||
        initialMessage) {
        return null;
    }
    if (isMyMessage() && displayLeft) {
        return (React.createElement("div", { className: "str-chat__message-" + theme + "__actions", "data-testid": 'message-options-left' },
            showActionsBox && (React.createElement(MessageActions, { ActionsIcon: ActionsIcon, messageWrapperRef: messageWrapperRef })),
            shouldShowReplies && (React.createElement("div", { className: "str-chat__message-" + theme + "__actions__action str-chat__message-" + theme + "__actions__action--thread", "data-testid": 'thread-action', onClick: handleOpenThread },
                React.createElement(ThreadIcon, null))),
            shouldShowReactions && (React.createElement("div", { className: "str-chat__message-" + theme + "__actions__action str-chat__message-" + theme + "__actions__action--reactions", "data-testid": 'message-reaction-action', onClick: onReactionListClick },
                React.createElement(ReactionIcon, null)))));
    }
    return (React.createElement("div", { className: "str-chat__message-" + theme + "__actions", "data-testid": 'message-options' },
        shouldShowReactions && (React.createElement("div", { className: "str-chat__message-" + theme + "__actions__action str-chat__message-" + theme + "__actions__action--reactions", "data-testid": 'message-reaction-action', onClick: onReactionListClick },
            React.createElement(ReactionIcon, null))),
        shouldShowReplies && (React.createElement("div", { className: "str-chat__message-" + theme + "__actions__action str-chat__message-" + theme + "__actions__action--thread", "data-testid": 'thread-action', onClick: handleOpenThread },
            React.createElement(ThreadIcon, null))),
        showActionsBox && (React.createElement(MessageActions, { ActionsIcon: ActionsIcon, messageWrapperRef: messageWrapperRef }))));
};
export var MessageOptions = React.memo(UnMemoizedMessageOptions);
