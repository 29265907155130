import React, { useMemo } from 'react';
import { useMessageContext } from '../../context/MessageContext';
import { isDate, isDayOrMoment, isNumberOrString, useTranslationContext, } from '../../context/TranslationContext';
export var defaultTimestampFormat = 'h:mmA';
export var notValidDateWarning = 'MessageTimestamp was called without a message, or message has invalid created_at date.';
export var noParsingFunctionWarning = 'MessageTimestamp was called but there is no datetime parsing function available';
function getDateString(messageCreatedAt, formatDate, calendar, tDateTimeParser, format) {
    if (!messageCreatedAt || !Date.parse(messageCreatedAt)) {
        console.warn(notValidDateWarning);
        return null;
    }
    if (typeof formatDate === 'function') {
        return formatDate(new Date(messageCreatedAt));
    }
    if (!tDateTimeParser) {
        console.warn(noParsingFunctionWarning);
        return null;
    }
    var parsedTime = tDateTimeParser(messageCreatedAt);
    if (isDayOrMoment(parsedTime)) {
        /**
         * parsedTime.calendar is guaranteed on the type but is only
         * available when a user calls dayjs.extend(calendar)
         */
        return calendar && parsedTime.calendar ? parsedTime.calendar() : parsedTime.format(format);
    }
    if (isDate(parsedTime)) {
        return parsedTime.toDateString();
    }
    if (isNumberOrString(parsedTime)) {
        return parsedTime;
    }
    return null;
}
var UnMemoizedMessageTimestamp = function (props) {
    var _a = props.calendar, calendar = _a === void 0 ? false : _a, _b = props.customClass, customClass = _b === void 0 ? '' : _b, _c = props.format, format = _c === void 0 ? defaultTimestampFormat : _c, propMessage = props.message;
    var _d = useMessageContext('MessageTimestamp'), formatDate = _d.formatDate, contextMessage = _d.message;
    var tDateTimeParser = useTranslationContext('MessageTimestamp').tDateTimeParser;
    var message = propMessage || contextMessage;
    var createdAt = message.created_at;
    var when = useMemo(function () { return getDateString(createdAt, formatDate, calendar, tDateTimeParser, format); }, [formatDate, calendar, tDateTimeParser, format, createdAt]);
    if (!when)
        return null;
    return (React.createElement("time", { className: customClass, dateTime: createdAt, title: createdAt }, when));
};
export var MessageTimestamp = React.memo(UnMemoizedMessageTimestamp);
