import React, { useEffect, useRef } from 'react';
import { useTranslationContext } from '../../context/TranslationContext';
export var Modal = function (props) {
    var children = props.children, onClose = props.onClose, open = props.open;
    var t = useTranslationContext('Modal').t;
    var innerRef = useRef(null);
    var handleClick = function (event) {
        var _a;
        if (event.target instanceof HTMLDivElement &&
            !((_a = innerRef.current) === null || _a === void 0 ? void 0 : _a.contains(event.target)) &&
            onClose) {
            onClose();
        }
    };
    useEffect(function () {
        if (!open)
            return function () { return null; };
        var handleEscKey = function (event) {
            if (event instanceof KeyboardEvent && event.key === 'Escape' && onClose) {
                onClose();
            }
        };
        document.addEventListener('keypress', handleEscKey);
        return function () { return document.removeEventListener('keypress', handleEscKey); };
    }, [onClose, open]);
    var openClasses = open ? 'str-chat__modal--open' : 'str-chat__modal--closed';
    return (React.createElement("div", { className: "str-chat__modal " + openClasses, onClick: handleClick },
        React.createElement("div", { className: 'str-chat__modal__close-button' },
            t('Close'),
            React.createElement("svg", { height: '10', width: '10', xmlns: 'http://www.w3.org/2000/svg' },
                React.createElement("path", { d: 'M9.916 1.027L8.973.084 5 4.058 1.027.084l-.943.943L4.058 5 .084 8.973l.943.943L5 5.942l3.973 3.974.943-.943L5.942 5z', fillRule: 'evenodd' }))),
        React.createElement("div", { className: 'str-chat__modal__inner', ref: innerRef }, children)));
};
