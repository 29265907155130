var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useContext } from 'react';
//@ts-expect-error
import DefaultEmojiIndex from 'emoji-mart/dist/utils/emoji-index/nimble-emoji-index.js';
var DefaultEmoji = React.lazy(function () { return __awaiter(void 0, void 0, void 0, function () {
    var emoji;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, import('emoji-mart/dist/components/emoji/nimble-emoji.js')];
            case 1:
                emoji = _a.sent();
                return [2 /*return*/, { default: emoji.default }];
        }
    });
}); });
var DefaultEmojiPicker = React.lazy(function () { return __awaiter(void 0, void 0, void 0, function () {
    var emojiPicker;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, import('emoji-mart/dist/components/picker/nimble-picker.js')];
            case 1:
                emojiPicker = _a.sent();
                return [2 /*return*/, { default: emojiPicker.default }];
        }
    });
}); });
export var EmojiContext = React.createContext(undefined);
export var EmojiProvider = function (_a) {
    var children = _a.children, value = _a.value;
    var _b = value.Emoji, Emoji = _b === void 0 ? DefaultEmoji : _b, emojiConfig = value.emojiConfig, _c = value.EmojiIndex, EmojiIndex = _c === void 0 ? DefaultEmojiIndex : _c, _d = value.EmojiPicker, EmojiPicker = _d === void 0 ? DefaultEmojiPicker : _d;
    var emojiContextValue = {
        Emoji: Emoji,
        emojiConfig: emojiConfig,
        EmojiIndex: EmojiIndex,
        EmojiPicker: EmojiPicker,
    };
    return React.createElement(EmojiContext.Provider, { value: emojiContextValue }, children);
};
export var useEmojiContext = function (componentName) {
    var contextValue = useContext(EmojiContext);
    if (!contextValue) {
        console.warn("The useEmojiContext hook was called outside of the EmojiContext provider. Make sure this hook is called within a child of the Channel component. The errored call is located in the " + componentName + " component.");
        return {};
    }
    return contextValue;
};
/**
 * Typescript currently does not support partial inference, so if EmojiContext
 * typing is desired while using the HOC withEmojiContext, the Props for the
 * wrapped component must be provided as the first generic.
 */
export var withEmojiContext = function (Component) {
    var WithEmojiContextComponent = function (props) {
        var componentContext = useEmojiContext();
        return React.createElement(Component, __assign({}, props, componentContext));
    };
    WithEmojiContextComponent.displayName = (Component.displayName ||
        Component.name ||
        'Component').replace('Base', '');
    return WithEmojiContextComponent;
};
