import React, { Suspense } from 'react';
import { useEmojiContext } from '../../context/EmojiContext';
import { useTranslationContext } from '../../context/TranslationContext';
import { useMessageInputContext } from '../../context/MessageInputContext';
var filterEmoji = function (emoji) {
    if (emoji.name === 'White Smiling Face' || emoji.name === 'White Frowning Face') {
        return false;
    }
    return true;
};
export var EmojiPicker = function (props) {
    var small = props.small;
    var _a = useEmojiContext('EmojiPicker'), emojiConfig = _a.emojiConfig, EmojiPickerComponent = _a.EmojiPicker;
    var t = useTranslationContext('EmojiPicker').t;
    var messageInput = useMessageInputContext('EmojiPicker');
    var emojiData = (emojiConfig || {}).emojiData;
    if (messageInput.emojiPickerIsOpen && emojiData) {
        var className = small
            ? 'str-chat__small-message-input-emojipicker'
            : 'str-chat__input--emojipicker';
        return (React.createElement("div", { className: className, ref: messageInput.emojiPickerRef },
            React.createElement(Suspense, { fallback: null },
                React.createElement(EmojiPickerComponent, { color: '#006CFF', data: emojiData, emoji: 'point_up', emojisToShowFilter: filterEmoji, native: true, onSelect: messageInput.onSelectEmoji, set: 'facebook', showPreview: false, showSkinTones: false, title: t('Pick your emoji'), useButton: true }))));
    }
    return null;
};
