/* eslint-disable */
export var KEY_CODES = {
    ESC: 27,
    UP: 38,
    DOWN: 40,
    ENTER: 13,
    TAB: 9,
    SPACE: 32,
};
var keycodeMap = {};
// This is self-made key shortcuts manager, used for caching key strokes
var Listener = /** @class */ (function () {
    function Listener() {
        var _this = this;
        this.startListen = function () {
            if (!_this.refCount) {
                // prevent multiple listeners in case of multiple TextareaAutocomplete components on page
                document.addEventListener('keydown', _this.f);
                document.addEventListener('keyup', _this.f);
            }
            _this.refCount++;
        };
        this.stopListen = function () {
            _this.refCount--;
            if (!_this.refCount) {
                // prevent disable listening in case of multiple TextareaAutocomplete components on page
                document.removeEventListener('keydown', _this.f);
                document.removeEventListener('keyup', _this.f);
            }
        };
        this.add = function (keyCodes, fn) {
            var keyCode = keyCodes;
            if (typeof keyCode !== 'object')
                keyCode = [keyCode];
            _this.listeners[_this.index] = {
                keyCode: keyCode,
                fn: fn,
            };
            _this.index += 1;
            return _this.index;
        };
        this.remove = function (ids) {
            for (var _i = 0, _a = Object.entries(ids); _i < _a.length; _i++) {
                var key = _a[_i];
                delete _this.listeners[key];
            }
        };
        this.removeAll = function () {
            _this.listeners = {};
            _this.index = 0;
        };
        this.index = 0;
        this.listeners = {};
        this.refCount = 0;
        this.f = function (e) {
            var code = e.keyCode || e.which;
            keycodeMap[code] = e.type === 'keydown';
            if (e.type !== 'keydown') {
                keycodeMap[code] = false;
                return;
            }
            Object.values(_this.listeners).forEach(function (_a) {
                var keyCode = _a.keyCode, fn = _a.fn;
                if (keyCode.length > 1) {
                    if (keyCode.every(function (keycode) { return keycodeMap[keycode]; })) {
                        fn(e);
                    }
                }
                else if (keyCode.includes(code) && keycodeMap[code]) {
                    fn(e);
                }
            });
        };
    }
    return Listener;
}());
export default new Listener();
