import React, { useEffect } from 'react';
import { FileUploadButton, ImageDropzone } from 'react-file-utils';
import { EmojiPicker } from './EmojiPicker';
import { EmojiIconSmall as DefaultEmojiIcon, FileUploadIcon as DefaultFileUploadIcon, } from './icons';
import { UploadsPreview } from './UploadsPreview';
import { KEY_CODES } from '../AutoCompleteTextarea/listener';
import { ChatAutoComplete } from '../ChatAutoComplete/ChatAutoComplete';
import { Tooltip } from '../Tooltip/Tooltip';
import { useChannelStateContext } from '../../context/ChannelStateContext';
import { useTranslationContext } from '../../context/TranslationContext';
import { useMessageInputContext } from '../../context/MessageInputContext';
import { useComponentContext } from '../../context/ComponentContext';
export var EditMessageForm = function () {
    var _a = useChannelStateContext('EditMessageForm'), acceptedFiles = _a.acceptedFiles, multipleUploads = _a.multipleUploads;
    var t = useTranslationContext('EditMessageForm').t;
    var _b = useMessageInputContext('EditMessageForm'), clearEditingState = _b.clearEditingState, handleSubmit = _b.handleSubmit, isUploadEnabled = _b.isUploadEnabled, maxFilesLeft = _b.maxFilesLeft, openEmojiPicker = _b.openEmojiPicker, uploadNewFiles = _b.uploadNewFiles;
    var _c = useComponentContext('EditMessageForm'), _d = _c.EmojiIcon, EmojiIcon = _d === void 0 ? DefaultEmojiIcon : _d, _e = _c.FileUploadIcon, FileUploadIcon = _e === void 0 ? DefaultFileUploadIcon : _e;
    useEffect(function () {
        var onKeyDown = function (event) {
            if (event.keyCode === KEY_CODES.ESC && clearEditingState)
                clearEditingState();
        };
        document.addEventListener('keydown', onKeyDown);
        return function () { return document.removeEventListener('keydown', onKeyDown); };
    }, [clearEditingState]);
    return (React.createElement("div", { className: 'str-chat__edit-message-form' },
        React.createElement(ImageDropzone, { accept: acceptedFiles, disabled: !isUploadEnabled || maxFilesLeft === 0, handleFiles: uploadNewFiles, maxNumberOfFiles: maxFilesLeft, multiple: multipleUploads },
            React.createElement("form", { onSubmit: handleSubmit },
                isUploadEnabled && React.createElement(UploadsPreview, null),
                React.createElement(EmojiPicker, { small: true }),
                React.createElement(ChatAutoComplete, null),
                React.createElement("div", { className: 'str-chat__message-team-form-footer' },
                    React.createElement("div", { className: 'str-chat__edit-message-form-options' },
                        React.createElement("span", { className: 'str-chat__input-emojiselect', onClick: openEmojiPicker },
                            React.createElement(EmojiIcon, null)),
                        isUploadEnabled && (React.createElement("div", { className: 'str-chat__fileupload-wrapper', "data-testid": 'fileinput' },
                            React.createElement(Tooltip, null, maxFilesLeft
                                ? t('Attach files')
                                : t("You've reached the maximum number of files")),
                            React.createElement(FileUploadButton, { accepts: acceptedFiles, disabled: maxFilesLeft === 0, handleFiles: uploadNewFiles, multiple: multipleUploads },
                                React.createElement("span", { className: 'str-chat__input-fileupload' },
                                    React.createElement(FileUploadIcon, null)))))),
                    React.createElement("div", null,
                        React.createElement("button", { onClick: function () {
                                if (clearEditingState) {
                                    clearEditingState();
                                }
                            } }, t('Cancel')),
                        React.createElement("button", { type: 'submit' }, t('Send'))))))));
};
