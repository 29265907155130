var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React from 'react';
import { isAudioAttachment, isFileAttachment, isGalleryAttachmentType, isImageAttachment, isMediaAttachment, renderAudio, renderCard, renderFile, renderGallery, renderImage, renderMedia, } from './utils';
/**
 * A component used for rendering message attachments. By default, the component supports: AttachmentActions, Audio, Card, File, Gallery, Image, and Video
 */
export var Attachment = function (props) {
    var attachments = props.attachments, rest = __rest(props, ["attachments"]);
    var gallery = {
        images: attachments === null || attachments === void 0 ? void 0 : attachments.filter(function (attachment) {
            return attachment.type === 'image' && !(attachment.og_scrape_url || attachment.title_link);
        }),
        type: 'gallery',
    };
    var newAttachments = gallery.images.length >= 2
        ? __spreadArray(__spreadArray([], attachments.filter(function (attachment) {
            return !(attachment.type === 'image' && !(attachment.og_scrape_url || attachment.title_link));
        })), [
            gallery,
        ]) : attachments;
    return (React.createElement(React.Fragment, null, newAttachments.map(function (attachment) {
        if (isGalleryAttachmentType(attachment)) {
            return renderGallery(__assign(__assign({}, rest), { attachment: attachment }));
        }
        if (isImageAttachment(attachment)) {
            return renderImage(__assign(__assign({}, rest), { attachment: attachment }));
        }
        if (isAudioAttachment(attachment)) {
            return renderAudio(__assign(__assign({}, rest), { attachment: attachment }));
        }
        if (isFileAttachment(attachment)) {
            return renderFile(__assign(__assign({}, rest), { attachment: attachment }));
        }
        if (isMediaAttachment(attachment)) {
            return renderMedia(__assign(__assign({}, rest), { attachment: attachment }));
        }
        return renderCard(__assign(__assign({}, rest), { attachment: attachment }));
    })));
};
