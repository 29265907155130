import React, { useState } from 'react';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { ModalComponent as ModalWrapper } from './ModalWrapper';
/**
 * A simple component that displays an image.
 */
export var ImageComponent = function (props) {
    var _a = useState(false), modalIsOpen = _a[0], setModalIsOpen = _a[1];
    var fallback = props.fallback, image_url = props.image_url, thumb_url = props.thumb_url;
    var imageSrc = sanitizeUrl(image_url || thumb_url);
    var formattedArray = [{ source: imageSrc }];
    var toggleModal = function () { return setModalIsOpen(!modalIsOpen); };
    return (React.createElement(React.Fragment, null,
        React.createElement("img", { alt: fallback, className: 'str-chat__message-attachment--img', "data-testid": 'image-test', onClick: toggleModal, src: imageSrc }),
        React.createElement(ModalWrapper, { images: formattedArray, index: 0, modalIsOpen: modalIsOpen, toggleModal: toggleModal })));
};
