import React from 'react';
export var LoadingIndicator = function (props) {
    var _a = props.size, size = _a === void 0 ? 20 : _a, _b = props.width, width = _b === void 0 ? 2 : _b, backgroundColor = props.backgroundColor, color = props.color;
    return (React.createElement("div", { className: "rfu-loading-indicator__spinner", style: {
            margin: '0 auto',
            borderColor: backgroundColor ? backgroundColor : '',
            borderTopColor: color ? color : '',
            width: size ? size : '',
            height: size ? size : '',
            borderWidth: width ? width : '',
        } }));
};
