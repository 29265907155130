import React from 'react';
import { Avatar as DefaultAvatar } from '../Avatar';
import { isDayOrMoment, useTranslationContext } from '../../context/TranslationContext';
/**
 * Component to display system and channel event messages
 */
var UnMemoizedEventComponent = function (props) {
    var _a, _b, _c;
    var _d = props.Avatar, Avatar = _d === void 0 ? DefaultAvatar : _d, message = props.message;
    var tDateTimeParser = useTranslationContext('EventComponent').tDateTimeParser;
    var _e = message.created_at, created_at = _e === void 0 ? '' : _e, event = message.event, text = message.text, type = message.type;
    var dateFormatter = function (date, format) {
        var parsedDate = tDateTimeParser(date);
        var formattedDate = isDayOrMoment(parsedDate) ? parsedDate.format(format) : parsedDate;
        return formattedDate;
    };
    if (type === 'system')
        return (React.createElement("div", { className: 'str-chat__message--system' },
            React.createElement("div", { className: 'str-chat__message--system__text' },
                React.createElement("div", { className: 'str-chat__message--system__line' }),
                React.createElement("p", null, text),
                React.createElement("div", { className: 'str-chat__message--system__line' })),
            React.createElement("div", { className: 'str-chat__message--system__date' },
                React.createElement("strong", null,
                    dateFormatter(created_at, 'dddd'),
                    " "),
                "at ",
                dateFormatter(created_at, 'hh:mm A'))));
    if ((event === null || event === void 0 ? void 0 : event.type) === 'member.removed' || (event === null || event === void 0 ? void 0 : event.type) === 'member.added') {
        var name_1 = ((_a = event.user) === null || _a === void 0 ? void 0 : _a.name) || ((_b = event.user) === null || _b === void 0 ? void 0 : _b.id);
        var sentence = name_1 + " " + (event.type === 'member.added' ? 'has joined the chat' : 'was removed from the chat');
        return (React.createElement("div", { className: 'str-chat__event-component__channel-event' },
            React.createElement(Avatar, { image: (_c = event.user) === null || _c === void 0 ? void 0 : _c.image, name: name_1, user: event.user }),
            React.createElement("div", { className: 'str-chat__event-component__channel-event__content' },
                React.createElement("em", { className: 'str-chat__event-component__channel-event__sentence' }, sentence),
                React.createElement("div", { className: 'str-chat__event-component__channel-event__date' }, dateFormatter(created_at, 'LT')))));
    }
    return null;
};
export var EventComponent = React.memo(UnMemoizedEventComponent);
