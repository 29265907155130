import React, { useCallback } from 'react';
import { LoadingIndicator } from './LoadingIndicator';
import { Thumbnail } from './Thumbnail';
import { ThumbnailPlaceholder } from './ThumbnailPlaceholder';
export var ImagePreviewer = function (props) {
    var _a = props.multiple, multiple = _a === void 0 ? true : _a, _b = props.disabled, disabled = _b === void 0 ? false : _b, imageUploads = props.imageUploads, handleRemove = props.handleRemove, handleRetry = props.handleRetry, handleFiles = props.handleFiles;
    var onClose = useCallback(function (id) {
        if (handleRemove) {
            if (id == null) {
                console.warn("id of closed image was undefined, this shouldn't happen");
                return;
            }
            handleRemove(id);
        }
    }, [handleRemove]);
    return (React.createElement("div", { className: "rfu-image-previewer" }, imageUploads === null || imageUploads === void 0 ? void 0 :
        imageUploads.map(function (image) {
            var url = image.url || image.previewUri;
            return (React.createElement("div", { key: image.id, className: "rfu-image-previewer__image" + (image.state === 'finished'
                    ? ' rfu-image-previewer__image--loaded'
                    : '') },
                image.state === 'failed' && (React.createElement("div", { className: "rfu-image-previewer__retry", dangerouslySetInnerHTML: {
                        __html: '<svg width="22" height="20" viewBox="0 0 22 20" xmlns="http://www.w3.org/2000/svg"><path d="M20 5.535V2a1 1 0 0 1 2 0v6a1 1 0 0 1-1 1h-6a1 1 0 0 1 0-2h3.638l-2.975-2.653a8 8 0 1 0 1.884 8.32 1 1 0 1 1 1.886.666A10 10 0 1 1 5.175 1.245c3.901-2.15 8.754-1.462 11.88 1.667L20 5.535z" fill="#FFF" fill-rule="nonzero"/></svg>',
                    }, onClick: handleRetry && (function () { return handleRetry(image.id); }) })),
                url !== undefined && (React.createElement(Thumbnail, { handleClose: onClose, image: url, id: image.id })),
                image.state === 'uploading' && (React.createElement(LoadingIndicator, { backgroundColor: "rgba(255,255,255,0.1)", color: "rgba(255,255,255,0.7)" }))));
        }),
        handleFiles && !disabled && (React.createElement(ThumbnailPlaceholder, { handleFiles: handleFiles, multiple: multiple }))));
};
