var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useState } from 'react';
import { useComponentContext } from '../../context/ComponentContext';
import { escapeRegExp } from '../../utils';
import { Item } from './Item';
import { DefaultSuggestionListHeader } from './Header';
import { KEY_CODES } from './listener';
export var List = function (props) {
    var className = props.className, component = props.component, currentTrigger = props.currentTrigger, dropdownScroll = props.dropdownScroll, getSelectedItem = props.getSelectedItem, getTextToReplace = props.getTextToReplace, PropHeader = props.Header, itemClassName = props.itemClassName, itemStyle = props.itemStyle, onSelect = props.onSelect, selectionEnd = props.selectionEnd, style = props.style, PropSuggestionItem = props.SuggestionItem, propValue = props.value, values = props.values;
    var _a = useComponentContext(), AutocompleteSuggestionHeader = _a.AutocompleteSuggestionHeader, AutocompleteSuggestionItem = _a.AutocompleteSuggestionItem;
    var SuggestionItem = PropSuggestionItem || AutocompleteSuggestionItem || Item;
    var SuggestionHeader = PropHeader || AutocompleteSuggestionHeader || DefaultSuggestionListHeader;
    var _b = useState(undefined), selectedItem = _b[0], setSelectedItem = _b[1];
    var itemsRef = [];
    var isSelected = function (item) {
        return selectedItem === values.findIndex(function (value) { return getId(value) === getId(item); });
    };
    var getId = function (item) {
        var textToReplace = getTextToReplace(item);
        if (textToReplace.key) {
            return textToReplace.key;
        }
        if (typeof item === 'string' || !item.key) {
            return textToReplace.text;
        }
        return item.key;
    };
    var modifyText = function (value) {
        if (!value)
            return;
        onSelect(getTextToReplace(value));
        if (getSelectedItem)
            getSelectedItem(value);
    };
    var handleClick = function (e) {
        var _a;
        if (e)
            (_a = e.preventDefault) === null || _a === void 0 ? void 0 : _a.call(e);
        modifyText(values[selectedItem]);
    };
    var selectItem = function (item) {
        var index = values.findIndex(function (value) {
            return value.id ? value.id === item.id : value.name === item.name;
        });
        setSelectedItem(index);
    };
    var handleKeyDown = useCallback(function (event) {
        if (event.which === KEY_CODES.UP) {
            setSelectedItem(function (prevSelected) {
                if (prevSelected === undefined)
                    return 0;
                var newID = prevSelected === 0 ? values.length - 1 : prevSelected - 1;
                dropdownScroll(itemsRef[newID]);
                return newID;
            });
        }
        if (event.which === KEY_CODES.DOWN) {
            setSelectedItem(function (prevSelected) {
                if (prevSelected === undefined)
                    return 0;
                var newID = prevSelected === values.length - 1 ? 0 : prevSelected + 1;
                dropdownScroll(itemsRef[newID]);
                return newID;
            });
        }
        if ((event.which === KEY_CODES.ENTER || event.which === KEY_CODES.TAB) &&
            selectedItem !== undefined) {
            handleClick(event);
            return setSelectedItem(undefined);
        }
        return null;
    }, [selectedItem, values]);
    useEffect(function () {
        document.addEventListener('keydown', handleKeyDown, false);
        return function () { return document.removeEventListener('keydown', handleKeyDown); };
    }, [handleKeyDown]);
    useEffect(function () {
        if (values === null || values === void 0 ? void 0 : values.length)
            selectItem(values[0]);
    }, [values]); // eslint-disable-line
    var restructureItem = function (item) {
        var matched = item.name || item.id;
        var textBeforeCursor = propValue.slice(0, selectionEnd);
        var triggerIndex = textBeforeCursor.lastIndexOf(currentTrigger);
        var editedPropValue = escapeRegExp(textBeforeCursor.slice(triggerIndex + 1));
        var parts = matched.split(new RegExp("(" + editedPropValue + ")", 'gi'));
        var itemNameParts = { match: editedPropValue, parts: parts };
        return __assign(__assign({}, item), { itemNameParts: itemNameParts });
    };
    return (React.createElement("ul", { className: "rta__list " + (className || ''), style: style },
        React.createElement("li", { className: 'rta__list-header' },
            React.createElement(SuggestionHeader, { currentTrigger: currentTrigger, value: propValue })),
        values.map(function (item, i) { return (React.createElement(SuggestionItem, { className: itemClassName, component: component, item: restructureItem(item), key: getId(item), onClickHandler: handleClick, onSelectHandler: selectItem, ref: function (ref) {
                itemsRef[i] = ref;
            }, selected: isSelected(item), style: itemStyle, value: propValue })); })));
};
