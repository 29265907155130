import React from 'react';
import prettybytes from 'pretty-bytes';
import { FileIcon } from 'react-file-utils';
import { SafeAnchor } from '../SafeAnchor';
var UnMemoizedFileAttachment = function (props) {
    var attachment = props.attachment;
    return (React.createElement("div", { className: 'str-chat__message-attachment-file--item', "data-testid": 'attachment-file' },
        React.createElement(FileIcon, { big: true, filename: attachment.title, mimeType: attachment.mime_type, size: 30 }),
        React.createElement("div", { className: 'str-chat__message-attachment-file--item-text' },
            React.createElement(SafeAnchor, { download: true, href: attachment.asset_url, target: '_blank' }, attachment.title),
            attachment.file_size && Number.isFinite(Number(attachment.file_size)) && (React.createElement("span", null, prettybytes(attachment.file_size))))));
};
export var FileAttachment = React.memo(UnMemoizedFileAttachment);
