(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports) :
  typeof define === 'function' && define.amd ? define(['exports'], factory) :
  (global = typeof globalThis !== 'undefined' ? globalThis : global || self, factory(global.translationCheck = {}));
}(this, (function (exports) { 'use strict';

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  var url = 'https://mini.locize.com';
  var showTranslations = function showTranslations(i18next) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var isI18next = i18next.store && i18next.store.toJSON;
    var opts = {
      sourceLng: options.sourceLng,
      targetLngs: options.targetLngs,
      preserveEmptyStrings: options.preserveEmptyStrings,
      i18nFormat: options.i18nFormat
    };
    var resources;

    if (isI18next) {
      if (!opts.sourceLng) opts.sourceLng = i18next.languages[0];
      if (!opts.targetLngs) opts.targetLngs = i18next.options.supportedLngs ? i18next.options.supportedLngs.filter(function (l) {
        return l !== i18next.languages[0] && l !== 'cimode';
      }) : undefined;
      if (!opts.preserveEmptyStrings) opts.preserveEmptyStrings = i18next.options.returnEmptyString;
      if (!opts.i18nFormat) opts.i18nFormat = i18next.options.compatibilityJSON && i18next.options.compatibilityJSON !== 'v4' ? 'i18next_v3' : 'i18next_v4';
      resources = i18next.store.toJSON();
    } else {
      resources = i18next;
    }

    var newWindow = window.open(url);
    var initInt = setInterval(function () {
      return newWindow.postMessage({
        message: 'isEditorReady'
      }, url);
    }, 1000);
    window.addEventListener('message', function (evt) {
      if (evt.origin !== url) return;

      if (evt.data.message === 'editorIsReady') {
        clearInterval(initInt);
        newWindow.postMessage(_objectSpread(_objectSpread({
          message: 'initializeProject'
        }, opts), {}, {
          resources: resources
        }), url);
      }
    });
  };
  var defaultOptions = {
    queryStringParam: 'showtranslations'
  };
  var i18nextPlugin = {
    type: '3rdParty',
    init: function init(i18next) {
      var options = _objectSpread(_objectSpread({}, defaultOptions), i18next.options.translationStats);

      i18next.on('initialized', function () {
        var params = new URLSearchParams(window.location.search);

        if (params.has(options.queryStringParam)) {
          showTranslations(i18next, options);
        }
      });
    }
  };

  exports.i18nextPlugin = i18nextPlugin;
  exports.showTranslations = showTranslations;

  Object.defineProperty(exports, '__esModule', { value: true });

})));
