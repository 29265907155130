import React from 'react';
import { AttachmentIcon } from './AttachmentIcon';
import { useHandleFileChangeWrapper } from '../utils';
export var FileUploadButton = function (_a) {
    var _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.multiple, multiple = _c === void 0 ? false : _c, _d = _a.children, children = _d === void 0 ? React.createElement(AttachmentIcon, null) : _d, handleFiles = _a.handleFiles, accepts = _a.accepts, _e = _a.resetOnChange, resetOnChange = _e === void 0 ? true : _e;
    var className = 'rfu-file-upload-button';
    if (disabled) {
        className = className + " rfu-file-upload-button--disabled";
    }
    var onFileChange = useHandleFileChangeWrapper(resetOnChange, handleFiles);
    return (React.createElement("div", { className: className },
        React.createElement("label", null,
            React.createElement("input", { type: "file", className: "rfu-file-input", onChange: onFileChange, multiple: multiple, disabled: disabled, accept: Array.isArray(accepts) ? accepts.join(',') : accepts }),
            children)));
};
