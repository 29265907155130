var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback } from 'react';
import Dropzone from 'react-dropzone';
export var ImageDropzone = function (_a) {
    var handleFiles = _a.handleFiles, maxNumberOfFiles = _a.maxNumberOfFiles, children = _a.children, accept = _a.accept, multiple = _a.multiple, disabled = _a.disabled;
    var handleDrop = useCallback(function (accepted) {
        if (!handleFiles) {
            return;
        }
        if (accepted && accepted.length) {
            handleFiles(accepted);
        }
    }, [handleFiles]);
    return (React.createElement(Dropzone, { onDrop: handleDrop, maxFiles: maxNumberOfFiles, accept: accept, multiple: multiple, disabled: disabled }, function (_a) {
        var getRootProps = _a.getRootProps, isDragAccept = _a.isDragAccept, isDragReject = _a.isDragReject;
        return (React.createElement("div", __assign({}, getRootProps({
            style: { position: 'relative' },
            className: "\n          rfu-dropzone\n          " + (isDragAccept ? 'rfu-dropzone--accept' : '') + "\n          " + (isDragReject ? 'rfu-dropzone--reject' : '') + "\n        ",
        })),
            React.createElement("div", { className: "rfu-dropzone__notifier" },
                React.createElement("div", { className: "rfu-dropzone__inner" },
                    React.createElement("svg", { width: "41", height: "41", viewBox: "0 0 41 41", xmlns: "http://www.w3.org/2000/svg" },
                        React.createElement("path", { d: "M40.517 28.002V3.997c0-2.197-1.808-3.992-4.005-3.992H12.507a4.004 4.004 0 0 0-3.992 3.993v24.004a4.004 4.004 0 0 0 3.992 3.993h24.005c2.197 0 4.005-1.795 4.005-3.993zm-22.002-7.997l4.062 5.42 5.937-7.423 7.998 10H12.507l6.008-7.997zM.517 8.003V36c0 2.198 1.795 4.005 3.993 4.005h27.997V36H4.51V8.002H.517z", fill: "#000", fillRule: "nonzero" })),
                    React.createElement("p", null, "Drag your files here to add to your post"))),
            children));
    }));
};
