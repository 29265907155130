import React from 'react';
import { archiveFileTypes, codeFileTypes, excelMimeTypes, powerpointMimeTypes, wordMimeTypes, } from './dataTypes';
import { FileAltIcon, FileArchiveIcon, FileAudioIcon, FileCodeIcon, FileExcelIcon, FileFallbackIcon, FileImageIcon, FilePdfIcon, FilePowerPointIcon, FileVideoIcon, FileWordIcon, } from './FileIconSet';
// Partially based on: https://stackoverflow.com/a/4212908/2570866
var mimeTypeToIconMap = {
    'application/pdf': FilePdfIcon,
};
for (var _i = 0, wordMimeTypes_1 = wordMimeTypes; _i < wordMimeTypes_1.length; _i++) {
    var type = wordMimeTypes_1[_i];
    mimeTypeToIconMap[type] = FileWordIcon;
}
for (var _a = 0, excelMimeTypes_1 = excelMimeTypes; _a < excelMimeTypes_1.length; _a++) {
    var type = excelMimeTypes_1[_a];
    mimeTypeToIconMap[type] = FileExcelIcon;
}
for (var _b = 0, powerpointMimeTypes_1 = powerpointMimeTypes; _b < powerpointMimeTypes_1.length; _b++) {
    var type = powerpointMimeTypes_1[_b];
    mimeTypeToIconMap[type] = FilePowerPointIcon;
}
for (var _c = 0, archiveFileTypes_1 = archiveFileTypes; _c < archiveFileTypes_1.length; _c++) {
    var type = archiveFileTypes_1[_c];
    mimeTypeToIconMap[type] = FileArchiveIcon;
}
for (var _d = 0, codeFileTypes_1 = codeFileTypes; _d < codeFileTypes_1.length; _d++) {
    var type = codeFileTypes_1[_d];
    mimeTypeToIconMap[type] = FileCodeIcon;
}
function mimeTypeToIcon(mimeType) {
    if (mimeType == null)
        return FileFallbackIcon;
    var icon = mimeTypeToIconMap[mimeType];
    if (icon)
        return icon;
    if (mimeType.startsWith('audio/'))
        return FileAudioIcon;
    if (mimeType.startsWith('video/'))
        return FileVideoIcon;
    if (mimeType.startsWith('image/'))
        return FileImageIcon;
    if (mimeType.startsWith('text/'))
        return FileAltIcon;
    return FileFallbackIcon;
}
export var FileIcon = function (props) {
    var _a = props.big, big = _a === void 0 ? false : _a, mimeType = props.mimeType, _b = props.size, size = _b === void 0 ? 50 : _b, _c = props.sizeSmall, sizeSmall = _c === void 0 ? 20 : _c;
    var Icon = mimeTypeToIcon(mimeType);
    return React.createElement(Icon, { size: big ? size : sizeSmall });
};
