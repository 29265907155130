import { useEffect, useState } from 'react';
import { useBreakpoint } from './useBreakpoint';
export var useMobilePress = function () {
    var _a = useState(null), targetMessage = _a[0], setTargetMessage = _a[1];
    var breakpoint = useBreakpoint();
    var handleMobilePress = function (event) {
        if (event.target instanceof HTMLElement && breakpoint.device === 'mobile') {
            var closestMessage = event.target.closest('.str-chat__message-simple');
            if (!closestMessage)
                return;
            setTargetMessage(closestMessage);
            if (closestMessage.classList.contains('mobile-press')) {
                closestMessage.classList.remove('mobile-press');
            }
            else {
                closestMessage.classList.add('mobile-press');
            }
        }
    };
    useEffect(function () {
        function handleClick(event) {
            var isClickInside = targetMessage === null || targetMessage === void 0 ? void 0 : targetMessage.contains(event.target);
            if (!isClickInside && targetMessage) {
                targetMessage.classList.remove('mobile-press');
            }
        }
        if (breakpoint.device === 'mobile') {
            document.addEventListener('click', handleClick);
        }
        return function () { return document.removeEventListener('click', handleClick); };
    }, [breakpoint, targetMessage]);
    return { handleMobilePress: handleMobilePress };
};
